<template>
  <div>
    <!-- header -->
    <div class="flex">
      <div class="bg-sg-liteblue w-1/4 h-0.5"></div>
      <div class="bg-sg-blue w-1/4 h-0.5"></div>
      <div class="bg-sg-liteblue w-1/4 h-0.5"></div>
      <div class="bg-sg-blue w-1/4 h-0.5"></div>
    </div>
    <header class="py-3 text-center">
      <div class="container md:py-4">
        <div class="md:flex md:justify-between md:items-center">
          <img
            src="/images/main-logo.png"
            alt=""
            class="w-3/4 mx-auto md:mx-0 md:w-60"
          />
          <div class="pt-6 text-lg font-medium md:text-base md:pt-0">
            <h1
              class="w-3/4 p-2 mx-auto text-gray-600 border-2 md:w-60 border-sg-blue"
            >
              Call Us :
              <a
                href="tel:+60361421180 "
                class="transition duration-300 ease-linear hover:text-sg-blue"
                >+603-61421180
              </a>
            </h1>
          </div>
        </div>
      </div>
    </header>

    <MainBanner />
    <!-- tagline  -->
    <div class="bg-sg-liteblue">
      <div class="container py-6">
        <p
          class="pb-3 text-sm leading-tight text-center text-white lg:mx-auto lg:max-w-3xl md:text-base"
        >
          We have got a team of highly qualified and experience dentists. We
          strive to do the very best of our treatment strictly according to your
          budget and requirements.
        </p>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container lg:flex lg:items-center">
        <div class="pb-4 lg:w-1/2 lg:pr-4 lg:pb-10">
          <div class="text-center lg:text-left">
            <h1 class="text-gray-600">About us</h1>
            <h1 class="text-2xl font-medium text-sg-blue">
              SIGNATURE DENTAL CLINIC
            </h1>
          </div>
          <div class="pt-6 space-y-2 text-sm font-light text-center">
            <p>
              <span class="font-semibold text-sg-blue"
                >SIGNATURE DENTAL CLINIC</span
              >
              is a sophisticated, patient-friendly clinic that has been
              established since from year 2011 with over 20,000 satisfied
              patients from the local Malaysian and 80 over foreign countries
              worldwide such as Australia, United States of America, Japan,
              Korea, Singapore, Thailand, Philippine, United Arab Emirates
              (UAE), Russia, Germany, United Kingdom, Canada, Switzerland, etc.
            </p>
            <p>
              The clinic strategically located at the ground floor corner unit
              facing the Maybank and Burger King near to MRT Surian Station and
              Sunway Giza Mall. Is a prime well developed township Kota
              Damansara, Petaling Jaya, Selangor. The clinic is specially being
              set-up with total care orientation for our patients. We practice
              to the highest standard quality of our dental care treatment.
            </p>
          </div>
        </div>
        <img
          src="/images/about-us.jpg"
          alt=""
          class="rounded-md shadow-md md:mx-auto md:w-2/3 lg:w-1/2 lg:block"
        />
      </div>
    </div>

    <!-- invisalign -->
    <div class="py-10 lg:py-0 lg:flex bg-sg-darkblue">
      <div class="container lg:w-1/2 lg:order-2">
        <div class="text-center text-white lg:px-4 lg:py-10">
          <h1 class="text-xl font-semibold">Smile Assessment</h1>
          <div class="bg-white w-10 h-0.5 mx-auto mt-2"></div>
          <h1 class="my-5 text-2xl font-bold leading-tight">
            Invisalign Is About Getting The Smile You Want, The Way You Want
          </h1>
          <p class="text-sm leading-tight">
            Introducing The World’s Most Advanced Teeth Straightening Technology
            To Correct All Imperfect Positioning Of The Teeth
          </p>

          <div class="flex flex-wrap mt-8 md:max-w-lg md:mx-auto">
            <div
              v-for="(item, i) in invisalign"
              :key="i"
              class="w-1/2 p-1 md:w-1/3"
            >
              <div
                class="p-4 transition duration-200 ease-linear bg-white rounded-md hover:shadow-md"
              >
                <img
                  :src="item.image + '.jpg'"
                  :alt="item.alt"
                  class="mx-auto"
                />
                <div class="mt-4">
                  <h1
                    class="py-2 mx-auto text-sm text-white rounded bg-sg-blue"
                  >
                    {{ item.alt }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hidden bg-center bg-no-repeat bg-cover lg:bg-top lg:w-1/2 lg:block"
        style="background-image: url('/images/bg-invisalign.jpg')"
      >
        <!-- <img src="/images/bg-invisalign.jpg" alt="" class="" /> -->
      </div>
    </div>

    <!-- why choose invisalign -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:items-center">
          <div class="md:w-1/2 lg:w-1/3">
            <h1
              class="text-2xl font-medium text-center md:text-left text-sg-blue"
            >
              Why Choose Invisalign?
            </h1>
            <div class="w-4/5 mx-auto mt-5 md:mx-0">
              <div v-for="list in choose_invisalign" :key="list" class="mb-1">
                <div class="inline-flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="30"
                    height="30"
                    class="fill-current text-sg-darkblue"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"
                    />
                  </svg>
                  <p class="pl-2 leading-tight">{{ list }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 md:w-1/2 lg:w-2/3">
            <img
              src="/images/choose-invisalign.jpg"
              alt=""
              class="rounded-md shadow-lg lg:mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- product and services  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-services.jpg')"
    >
      <div class="container">
        <div>
          <div class="text-center">
            <h1 class="text-gray-600">Our Services</h1>
            <h1 class="text-2xl font-medium leading-tight text-sg-blue">
              OUR STATE-OF-THE-ART SIGNATURE STANDARD
            </h1>
          </div>
          <div class="mt-8 md:flex md:flex-wrap lg:justify-center">
            <div
              v-for="(item, i) in services"
              :key="i"
              class="w-4/5 mx-auto mb-3 lg:mx-0 md:p-1 md:w-1/3 lg:w-1/4"
            >
              <div
                class="transition duration-200 ease-linear transform bg-white rounded-lg hover:shadow"
              >
                <img :src="item.image" alt="" class="rounded-t-md" />
                <div
                  class="px-3 py-5 text-base leading-tight text-center text-gray-600"
                >
                  <p>
                    {{ item.p }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex mt-5 space-x-1 md:justify-center">
            <h1
              class="w-40 py-2 font-medium text-center text-white transition duration-200 ease-linear transform rounded hover:scale-105 bg-sg-blue"
            >
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                >Enquire Us</a
              >
            </h1>
            <h1
              class="w-40 py-2 font-medium text-center text-white transition duration-200 ease-linear transform rounded hover:scale-105 bg-sg-blue"
            >
              <a href="https://wa.me/60123943188">Whatsapp Us</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <div class="text-center">
          <h1 class="text-gray-600">Why</h1>
          <h1 class="text-2xl font-medium text-sg-blue">Choose Us</h1>
        </div>
        <div class="flex flex-wrap pt-6 md:justify-center">
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 p-2 text-center md:w-1/4"
          >
            <img :src="item.image" :alt="item.alt" class="w-24 mx-auto" />
            <h1 class="pt-4 text-sm leading-tight">{{ item.alt }}</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- comparison banner -->
    <div class="lg:relative bg-gray-50 lg:bg-white">
      <h1
        class="left-0 right-0 py-5 text-2xl font-medium leading-tight text-center lg:absolute lg:mx-auto lg:text-white text-sg-blue"
      >
        Invisalign vs Traditional Braces
      </h1>
      <div class="md:flex">
        <!-- left side -->
        <div
          class="p-10 bg-center bg-no-repeat bg-cover lg:py-32 lg:w-1/2"
          style="background-image: url('/images/sub-invisalign.jpg')"
        >
          <div class="lg:ml-auto xl:w-2/3">
            <div
              class="p-6 transition ease-linear transform bg-white rounded-md hover:bg-white hover:scale-110 bg-opacity-60"
            >
              <h1 class="mb-3 text-2xl lg:text-3xl text-sg-blue">Invisalign</h1>
              <ul
                class="pl-2 space-y-1 text-sm leading-tight list-disc list-outside lg:text-base md:h-32"
              >
                <li>Clear aligners are virtually invisible</li>
                <li>Smooth comfortable plastic aligners</li>
                <li>Aligners are removable for easy cleaning</li>
                <li>Eat whatever you want</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- right side -->
        <div
          class="p-10 bg-center bg-no-repeat bg-cover lg:py-32 lg:w-1/2"
          style="background-image: url('/images/sub-braces.jpg')"
        >
          <div class="lg:mr-auto xl:w-2/3">
            <div
              class="p-6 transition ease-linear transform bg-white rounded-md hover:bg-white hover:scale-110 bg-opacity-60"
            >
              <h1 class="mb-3 text-2xl lg:text-3xl text-sg-blue">Braces</h1>
              <ul
                class="pl-2 space-y-1 text-sm leading-tight list-disc list-outside lg:text-base md:h-32"
              >
                <li>Requires brackets and wires</li>
                <li>Wires can poke and irritate mouth</li>
                <li>Brushing and flossing require more effort</li>
                <li>Many food restrictions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- sub whatsapp cta -->
      <div class="left-0 py-5 lg:absolute lg:bottom-0 lg:right-0 lg:mx-auto">
        <h1
          class="w-4/5 py-2 mx-auto text-center text-white transition duration-200 ease-linear transform rounded md:w-80 hover:scale-110 hover:shadow-md bg-sg-darkblue"
        >
          <a href="https://wa.me/60123943188"
            >Get Started Now For FREE 3D Scan!</a
          >
        </h1>
      </div>
    </div>

    <!-- testimonials -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-testimonial.jpg')"
    >
      <div class="container">
        <div class="text-center">
          <h1 class="text-white">Our</h1>
          <h1 class="text-2xl font-medium text-white">Testimonials</h1>
        </div>
        <div>
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
            }"
          >
            <div
              v-for="(item, i) in testimonial"
              :key="i"
              class="pt-10 text-center"
            >
              <p class="text-base font-normal leading-tight text-white">
                {{ item.text }}
              </p>
              <h1 class="pt-6 text-lg font-bold text-white">
                {{ item.client }}
              </h1>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- enquire form  -->
    <div class="pt-10 bg-sg-liteblue lg:flex lg:pt-0">
      <div class="container lg:py-10 lg:w-1/2">
        <div id="element" class="pb-10">
          <h1 class="pb-4 text-2xl font-medium text-center text-white">
            Make An Appointment
          </h1>
          <EnquiryForm class="lg:max-w-3xl lg:mx-auto" />
        </div>
      </div>
      <div
        class="bg-center bg-no-repeat bg-cover h-60 lg:w-1/2 lg:h-auto"
        style="background-image: url('/images/bg-enquiry.jpg')"
      >
        <!-- <img src="/images/bg-enquiry.jpg" alt=""> -->
      </div>
    </div>

    <footer class="py-10">
      <div class="container">
        <div class="lg:flex">
          <div class="text-center lg:w-1/2">
            <img
              src="/images/main-logo.png"
              alt=""
              class="w-4/5 mx-auto md:w-56"
            />
            <h1 class="pt-4 leading-tight text-gray-700">
              No.1, Ground Floor, Jalan PJU 5/10, Dataran Sunway, Kota
              Damansara, 47810 Petaling Jaya, Selangor, Malaysia.
            </h1>
            <div class="pt-4">
              <h1>Consultation Hours:</h1>
              <p class="text-sm text-gray-600">
                Mon - Sat: 9.00am - 6.00pm <br />
                Sun: 9.00am - 4.00pm <br />
                Closed On Selected Public Holidays
              </p>
              <h1 class="pt-3 text-gray-600">
                Call Us At
                <a
                  href="tel:+603-61421180"
                  class="transition duration-300 ease-linear hover:text-ting-mediumgreen"
                  >03-61421180</a
                >
              </h1>
            </div>
          </div>
          <div class="pt-5 lg:pt-0 lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.78493388198!2d101.59032161469722!3d3.1513481540180748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ede32e4f167%3A0x4abc7ccd06ea220f!2zU0lHTkFUVVJFIERFTlRBTCBDTElOSUMg5oSb5b-D6b2S56eR!5e0!3m2!1sen!2smy!4v1660036326739!5m2!1sen!2smy"
              width="100%"
              height="350"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>

    <!-- Copyright -->
    <div class="py-3 bg-sg-darkblue">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";

export default {
  name: "Home",
  components: {
    MainBanner,
    carousel,
    EnquiryForm,
  },
  data() {
    return {
      services: [
        { image: "/images/service-1.jpg", p: "Invisalign" },
        { image: "/images/service-2.jpg", p: "Veneer" },
        { image: "/images/service-3.jpg", p: "Oral Care for Children" },
        { image: "/images/service-4.jpg", p: "Scaling and Polishing" },
        { image: "/images/service-5.jpg", p: "Dental Fillings" },
        { image: "/images/service-6.jpg", p: "Teeth Whitening" },
        { image: "/images/service-7.jpg", p: "Braces" },
        { image: "/images/service-8.jpg", p: "Root Canal Treatment" },
        { image: "/images/service-9.jpg", p: "Crowns and Bridges" },
        { image: "/images/service-10.jpg", p: "Dental Implant" },
        { image: "/images/service-11.jpg", p: "Denture" },
        { image: "/images/service-12.jpg", p: "Wisdom Teeth Removal" },
      ],
      choose: [
        {
          image: "/images/choose-1.png",
          alt: "Professional and certified dentists",
        },
        {
          image: "/images/choose-2.png",
          alt: "High quality treatment and services",
        },
        {
          image: "/images/choose-3.png",
          alt: "Cosy and comfortable environment",
        },
        { image: "/images/choose-5.png", alt: "Cutting-edge technology" },
        {
          image: "/images/choose-6.png",
          alt: " Conveniently located at Kota Damansara, Petaling Jaya",
        },
        { image: "/images/choose-7.png", alt: "Operating 7 days a week" },
        // { image: "/images/choose-4.png", alt: "Personalised dental care" },
      ],
      testimonial: [
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 1",
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 2",
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 3",
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec odio vitae neque pulvinar gravida. Maecenas pellentesque eget felis eu laoreet. Fusce ac luctus dui. Proin lobortis sollicitudin velit, vitae dictum felis accumsan eu.",
          client: "Client 4",
        },
      ],
      invisalign: [
        { image: "/images/invisalign-1", alt: "SPACING" },
        { image: "/images/invisalign-2", alt: "OVERJET" },
        { image: "/images/invisalign-3", alt: "CROSSBITE" },
        { image: "/images/invisalign-4", alt: "OPEN BITE" },
        { image: "/images/invisalign-5", alt: "CROWNDING" },
        { image: "/images/invisalign-6", alt: "UNDERBITE" },
      ],
      choose_invisalign: [
        "Virtually Invisible",
        "More comfortable",
        "You can eat whatever you want",
        "Shorter treatment duration",
        "Lesser dental visits",
      ],
    };
  },
};
</script>
